<!--<link rel="stylesheet" href="../../../ivmax/resources/sass/admin.scss">-->
<template>
  <div class="wrapper">
    <nav
      class="main-header navbar navbar-expand py-3 navbar-white navbar-light"
      style="z-index: 2"
      :class="{
        'open-sidemenu-content': sidemenu,
        'close-sidemenu-content': !sidemenu,
      }"
    >
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link"
            @click="sidemenu = !sidemenu"
            data-widget="pushmenu"
            href="#"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 32 42"
              xmlns="http://www.w3.org/2000/svg"
              :class="{ active: sidemenu }"
            >
              <g transform="matrix(1,0,0,1,-389.5,-264.004)">
                <g id="arrow_left2">
                  <g transform="matrix(1,0,0,1,0,5)">
                    <path
                      id="top"
                      d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                    />
                  </g>
                  <g
                    transform="matrix(1,1.22465e-16,1.22465e-16,-1,0.00024296,564.935)"
                  >
                    <path
                      id="bottom"
                      d="M390,270L420,270L420,270C420,270 420.195,250.19 405,265C389.805,279.81 390,279.967 390,279.967"
                    />
                  </g>
                  <path id="middle" d="M390,284.967L420,284.967" />
                </g>
              </g>
            </svg>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">
            <p class="pageTitle ml-2 d-md-none d-sm-block">
              {{ pageName | truncate(20) }}
            </p>
            <p class="pageTitle ml-2 d-none d-md-block">{{ pageName }}</p>
          </a>
        </li>
      </ul>

      <ul class="navbar-nav ml-auto mr-4">
        <li class="nav-item dropdown profileName mr-2">
          <a
            href="#"
            @click.prevent="notiDropdown = !notiDropdown"
            v-click-outside="closeNotiDropdown"
            class="nav-link btn d-flex"
          >
            <img src="@/assets/icons/bell.svg" alt="" />
            <span
              v-if="notifications.length > 0"
              class="badge badge-danger navbar-badge"
              >{{ notifications.length }}</span
            >
          </a>

          <div
            v-if="notiDropdown"
            class="
              notiDropdown
              dropdown-menu
              show
              dropdown-menu-xlg dropdown-menu-right
              profileDropdown
            "
          >
            <a
              v-for="n in notifications"
              :key="n.id"
              @click.prevent="openNotification(n)"
              class="dropdown-item cursor-pointer"
            >
              <!--              <img src="@/assets/icons/moj-profil.svg" alt="" />-->
              <div class="noti-content" v-if="n.for_support">
                <p v-if="n.comment">
                  {{ n.comment }}
                </p>
                <p v-else>
                  {{ n.type === "new" ? "Nova oprema" : "Popravka" }} -
                  {{ n.user }} ID: {{ n.request_id }}
                </p>
              </div>
              <p class="noti-content" v-else-if="n.is_document">
                Dodijeljena nova oprema
                <br />
                <small>{{ n.document }}</small>
              </p>
              <p class="noti-content" v-else-if="n.admin_notification">
                {{ n.comment }}
              </p>
              <div v-else>
                <p class="noti-content">
                  {{
                    n.accepted
                      ? `Odobren zahtjev za ${
                          n.new ? "novu opremu" : "popravku postojece opreme"
                        } ID ${n.request_id}`
                      : `Odbijen zahtjev za ${
                          n.new ? "novu opremu" : "popravku postojece opreme"
                        } ID ${n.request_id}`
                  }}
                </p>
                <div v-if="!n.accepted">
                  <p class="noti-content">
                    {{ n.comment ? "Komentar: " + n.comment : "" }}
                  </p>
                </div>
              </div>
            </a>
            <a href="#" v-if="notifications.length < 1" class="dropdown-item">
              <p class="noti-content">
                {{ $t("no_notifications") }}
              </p>
            </a>
            <div v-if="notifications.length > 0" class="dropdown-divider"></div>
            <a
              v-if="notifications.length > 0"
              href="#"
              @click="clearNotification"
              class="dropdown-item"
            >
              <img src="@/assets/icons/times.svg" alt="" />
              <p class="noti-content">{{ $t("clear_notifications") }}</p>
            </a>
          </div>
        </li>
        <li
          class="nav-item dropdown profileName"
          :class="{ activeDropdown: profileDropdown }"
        >
          <a
            class="nav-link flex"
            @click.prevent="profileDropdown = !profileDropdown"
            data-toggle="dropdown"
            href="#"
          >
            <p class="profileFullname d-none d-md-block">
              {{ currUser.full_name }}
            </p>
            <p class="profileFullname d-block d-md-none">
              {{ currUser.initials }}
            </p>
          </a>
          <div
            v-if="profileDropdown"
            class="
              dropdown-menu
              show
              dropdown-menu-lg dropdown-menu-right
              profileDropdown
            "
          >
            <a href="#" @click="profile" class="dropdown-item">
              <img src="@/assets/icons/moj-profil.svg" alt="" />
              <p>{{ $t("my_profile") }}</p>
            </a>
            <router-link
              :to="{ name: 'MyEquipment' }"
              @click="$toast.info('Work in progress')"
              class="dropdown-item"
            >
              <img src="@/assets/icons/rented-equipment.svg" alt="" />
              <p>{{ $t("rented_equipment") }}</p>
            </router-link>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item" @click.prevent="logout">
              <img src="@/assets/icons/logout.svg" alt="" />
              <p>{{ $t("logout") }}</p>
            </a>
          </div>
        </li>
      </ul>
    </nav>

    <aside
      :class="{ 'open-sidemenu': sidemenu, 'close-sidemenu': !sidemenu }"
      class="main-sidebar sidebar-dark-primary elevation-4"
    >
      <a href="/admin" class="p-2" style="width: 100%">
        <img
          v-tooltip.top-center="`v1.3.1 alpha`"
          src="logo.png"
          alt=""
          class="brand-image mt-4 w-75"
        />
      </a>

      <div class="sidebar mt-5">
        <nav class="mt-2 pb-2">
          <!--            <li-->
          <!--              class="nav-item pb-2 my-2"-->
          <!--              style="border-bottom: 1px #4b545c solid"-->
          <!--            >-->
          <!--              <a-->
          <!--                class="nav-link current-user"-->
          <!--                style="background-color: #096677"-->
          <!--              >-->
          <!--                <i class="fas fa-user nav-icon"></i>-->
          <!--                <p>{{ currUser.full_name }}</p>-->
          <!--              </a>-->
          <!--            </li>-->

          <!--            <li class="nav-header pt-3">STORE</li>-->

          <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li
              class="nav-item"
              v-if="is('super-admin|support')"
              :class="{ 'menu-is-opening menu-is-open': openSubmenu }"
            >
              <a
                href="#"
                @click="openSubmenu = !openSubmenu"
                :class="{ dropper: openSubmenu }"
                class="nav-link"
              >
                <img src="@/assets/icons/requests.svg" alt="" />
                <p>
                  {{ $t("navbar.requests") }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item br-1">
                  <router-link class="nav-link" :to="{ name: 'NewEquipment' }">
                    <img src="@/assets/icons/new.svg" alt="" />
                    <p>{{ $t("navbar.new_equipment") }}</p>
                  </router-link>
                </li>
                <li class="nav-item br-1">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'RepairEquipment' }"
                  >
                    <img src="@/assets/icons/fix.svg" alt="" />
                    <p>{{ $t("navbar.fix_equipment") }}</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item"
              v-if="is('super-admin|user')"
              :class="{ 'menu-is-opening menu-is-open': openSubmenu2 }"
            >
              <a
                href="#"
                @click="openSubmenu2 = !openSubmenu2"
                class="nav-link"
              >
                <img src="@/assets/icons/requests.svg" alt="" />
                <p>
                  {{ $t("navbar.my_requests") }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'RequestNewEquipment' }"
                  >
                    <img src="@/assets/icons/new.svg" alt="" />
                    <p>{{ $t("navbar.new_equipment") }}</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'RequestRepairEquipment' }"
                  >
                    <img src="@/assets/icons/fix.svg" alt="" />
                    <p>{{ $t("navbar.fix_equipment") }}</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item"
              v-if="is('super-admin|support')"
              :class="{ 'menu-is-opening menu-is-open': openSubmenu3 }"
            >
              <a
                href="#"
                @click="openSubmenu3 = !openSubmenu3"
                class="nav-link"
              >
                <img src="@/assets/icons/karton-opreme.svg" alt="" />
                <p>
                  {{ $t("navbar.equipment_document") }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item br-1" v-if="is('super-admin|support')">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'EquipmentDocument' }"
                  >
                    <img src="@/assets/icons/active.svg" height="24" alt="" />
                    <p>Aktivna zaduženja</p>
                  </router-link>
                </li>
                <li class="nav-item br-1" v-if="is('super-admin|support')">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'EquipmentDocumentHistory' }"
                  >
                    <img src="@/assets/icons/history.svg" height="24" alt="" />
                    <p>Istorija zaduženja</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item" v-if="is('super-admin|support')">
              <router-link class="nav-link" :to="{ name: 'Reports' }">
                <img src="@/assets/icons/izvestaji.svg" alt="" />
                <p>{{ $t("navbar.reports") }}</p>
              </router-link>
            </li>

            <li
              class="nav-item"
              v-if="is('super-admin|support')"
              :class="{ 'menu-is-opening menu-is-open': openSubmenu4 }"
            >
              <a
                href="#"
                @click="openSubmenu4 = !openSubmenu4"
                class="nav-link"
              >
                <img src="@/assets/icons/sifrarnik.svg" alt="" />
                <p>
                  {{ $t("navbar.equipment") }}
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item br-1" v-if="is('super-admin|support')">
                  <router-link class="nav-link" :to="{ name: 'Equipment' }">
                    <img src="@/assets/icons/sifrarnik.svg" alt="" />
                    <p>Sva oprema</p>
                  </router-link>
                </li>
                <li class="nav-item br-1" v-if="is('super-admin|support')">
                  <router-link class="nav-link" :to="{ name: 'Category' }">
                    <!--                <i class="fad fa-globe-americas nav-icon"></i>-->
                    <img src="@/assets/icons/category.svg" height="24" alt="" />
                    <p>Kategorizacija</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li
              class="nav-item"
              v-if="is('super-admin|support')"
              :class="{ 'menu-is-opening menu-is-open': openSubmenu5 }"
            >
              <a
                href="#"
                @click="openSubmenu5 = !openSubmenu5"
                class="nav-link"
              >
                <img src="@/assets/icons/service.svg" height="24" alt="" />
                <p>
                  Servis
                  <i class="fas fa-angle-left right"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item br-1" v-if="is('super-admin|support')">
                  <router-link class="nav-link" :to="{ name: 'Service' }">
                    <img src="@/assets/icons/service.svg" height="24" alt="" />
                    <p>Aktivni servis</p>
                  </router-link>
                </li>
                <li class="nav-item br-1" v-if="is('super-admin|support')">
                  <router-link
                    class="nav-link"
                    :to="{ name: 'ServiceHistory' }"
                  >
                    <!--                <i class="fad fa-globe-americas nav-icon"></i>-->
                    <img src="@/assets/icons/service.svg" height="24" alt="" />
                    <p>Istorija servisa</p>
                  </router-link>
                </li>
              </ul>
            </li>

            <li class="nav-item" v-if="is('super-admin|support')">
              <router-link class="nav-link" :to="{ name: 'Notification' }">
                <img src="@/assets/icons/sidebar-bell.svg" alt="" />
                <p>{{ $t("navbar.notifications") }}</p>
              </router-link>
            </li>

            <li class="nav-item" v-if="is('super-admin|support')">
              <router-link class="nav-link" :to="{ name: 'Users' }">
                <img src="@/assets/icons/users.svg" alt="" />
                <p>{{ $t("navbar.users") }}</p>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </aside>

    <div
      class="content-wrapper"
      :class="{
        'open-sidemenu-content': sidemenu,
        'close-sidemenu-content': !sidemenu,
      }"
    >
      <!--      <div class="content-header">-->
      <!--        <div class="container-fluid">-->
      <!--          <div class="row mb-2">-->
      <!--            <div class="col-sm-6">-->
      <!--              <h1 class="m-0 text-dark">-->
      <!--                {{ pageName }}-->
      <!--              </h1>-->
      <!--            </div>-->
      <!--            <div class="col-sm-6">-->
      <!--              <ol class="breadcrumb float-sm-right">-->
      <!--                <li class="breadcrumb-item">-->
      <!--                  <router-link :to="{ name: 'admin' }">Admin</router-link>-->
      <!--                </li>-->
      <!--                <li-->
      <!--                  class="breadcrumb-item"-->
      <!--                  v-for="(url, index) in breadcrumbUrls"-->
      <!--                  :key="index"-->
      <!--                >-->
      <!--                  <router-link :to="{ name: url.name }">-->
      <!--                    {{ url.text }}-->
      <!--                  </router-link>-->
      <!--                </li>-->
      <!--                <li class="breadcrumb-item active">-->
      <!--                  {{ pageName }}-->
      <!--                </li>-->
      <!--              </ol>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <section id="content" class="content p-2">
        <div class="container-fluid main p-4">
          <router-view
            ref="child"
            @loadBreadcrumbLink="showBreadcrumbLink"
          ></router-view>
        </div>
      </section>
    </div>

    <footer class="main-footer" style="padding-left: 50px">
      Copyright &copy; {{ year }}.
      <strong
        >Powered by
        <a href="https://amplitudo.me" target="_blank">Amplitudo</a>.
      </strong>
    </footer>

    <b-modal id="myProfile" hide-footer centered scrollable title="Moj profil">
      <div class="form mb-4">
        <div class="form-group mb-3">
          <label for="name">Ime*</label>
          <b-form-input
            id="name"
            v-model="form.first_name"
            type="text"
            :class="{ 'is-invalid': form.errors.has('first_name') }"
            trim
          ></b-form-input>
          <has-error :form="form" field="first_name" />
        </div>
      </div>

      <div class="form mb-4">
        <div class="form-group mb-3">
          <label for="name">Prezime*</label>
          <b-form-input
            id="name"
            v-model="form.last_name"
            type="text"
            :class="{ 'is-invalid': form.errors.has('last_name') }"
            trim
          ></b-form-input>
          <has-error :form="form" field="last_name" />
        </div>
      </div>

      <div class="form mb-4">
        <div class="form-group mb-3">
          <label for="name">Nova lozinka</label>
          <b-input-group class="passwordAppend">
            <template #append>
              <b-input-group-text
                ><strong
                  ><i
                    class="fas"
                    @click="showPass = !showPass"
                    :class="{ 'fa-eye': !showPass, 'fa-eye-slash': showPass }"
                  ></i></strong
              ></b-input-group-text>
            </template>
            <b-form-input
              placeholder="Unesi lozinku..."
              id="password"
              v-model="form.password"
              :type="showPass ? 'text' : 'password'"
              trim
            >
            </b-form-input>
          </b-input-group>
          <has-error :form="form" field="password" />
        </div>
      </div>

      <div class="form mb-4">
        <div class="form-group mb-3">
          <label for="name">Potvrda nove lozinke</label>
          <b-input-group class="passwordAppend">
            <template #append>
              <b-input-group-text
                ><strong
                  ><i
                    class="fas"
                    @click="showPass2 = !showPass2"
                    :class="{ 'fa-eye': !showPass2, 'fa-eye-slash': showPass2 }"
                  ></i></strong
              ></b-input-group-text>
            </template>
            <b-form-input
              placeholder="Ponovi lozinku..."
              id="password"
              v-model="form.password_confirmation"
              :type="showPass2 ? 'text' : 'password'"
              trim
            >
            </b-form-input>
          </b-input-group>
        </div>
      </div>

      <div class="saveButton pb-4 mb-4">
        <b-button
          @click.prevent="submitForm"
          :disabled="form.busy"
          v-b-modal.modal-1
        >
          <b-spinner small v-if="form.busy"></b-spinner>
          {{ $t("save") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import Swal from "sweetalert2";
// import { API_URL } from "@/_plugins/axios";
import { mapGetters, mapActions } from "vuex";
import Form from "@/_plugins/form";
import i18n from "../_plugins/i18n";
import { API_URL } from "../_plugins/axios";
import HasError from "@/components/HasError";

export default {
  components: {
    HasError,
  },
  data() {
    return {
      breadcrumbUrls: [],
      pageName: "",
      currUser: "",
      notiCount: 0,
      showPass: false,
      showPass2: false,
      profileDropdown: false,
      notiDropdown: false,
      openSubmenu: false,
      form: new Form({
        first_name: "",
        last_name: "",
      }),
      openSubmenu2: false,
      openSubmenu3: false,
      openSubmenu4: false,
      openSubmenu5: false,
      sidemenu: !this.isMobile(),
    };
  },
  computed: {
    ...mapGetters({
      notifications: "notifications",
      user: "auth/user",
    }),
    year() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions(["clearNotifications", "removeNotification"]),
    openNotification(n) {
      if (!n.admin_notification) {
        if (
          JSON.parse(localStorage.getItem("permissions")).roles.includes(
            "support"
          ) ||
          JSON.parse(localStorage.getItem("permissions")).roles.includes(
            "super-admin"
          )
        ) {
          if (this.$route.name !== "NewEquipment" && n.type === "new") {
            this.$router.push({ name: "NewEquipment" });
          } else if (this.$route.name === "NewEquipment" && n.type === "new") {
            this.$refs.child.getMyRequests();
          } else if (
            this.$route.name !== "RepairEquipment" &&
            n.type !== "new"
          ) {
            this.$router.push({ name: "RepairEquipment" });
          } else {
            this.$refs.child.getMyRequests();
          }
        } else {
          if (
            this.$route.name !== "RequestNewEquipment" &&
            n.type === "new" &&
            !n.is_document
          ) {
            this.$router.push({ name: "RequestNewEquipment" });
          } else if (
            this.$route.name === "RequestNewEquipment" &&
            n.type === "new" &&
            !n.is_document
          ) {
            this.$refs.child.getMyRequests();
          } else if (
            this.$route.name !== "RequestRepairEquipment" &&
            n.type !== "new" &&
            !n.is_document
          ) {
            this.$router.push({ name: "RequestRepairEquipment" });
          } else if (
            this.$route.name !== "MyEquipment" &&
            n.is_document === true
          ) {
            this.$router.push({ name: "MyEquipment" });
          } else if (
            this.$route.name === "MyEquipment" &&
            n.is_document === true
          ) {
            this.$refs.child.getAllEquipment();
          } else {
            this.$refs.child.getMyRequests();
          }
        }
      }
      this.removeNotification(n);
    },
    profile() {
      this.form.first_name = this.currUser.first_name;
      this.form.last_name = this.currUser.last_name;
      this.$bvModal.show("myProfile");
    },
    submitForm() {
      this.form.put("users").then((res) => {
        this.currUser = res.data;
        this.$store.dispatch("auth/updateUser", res.data);
        this.$toast.success(i18n.tc("notifications.success"));
        this.$bvModal.hide("myProfile");
        this.form.reset();
      });
    },
    closeProfileDropdown() {
      this.profileDropdown = false;
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    },
    closeNotiDropdown() {
      this.notiDropdown = false;
    },
    clearNotification() {
      if (
        JSON.parse(localStorage.getItem("permissions")).roles.includes(
          "support"
        ) ||
        JSON.parse(localStorage.getItem("permissions")).roles.includes(
          "super-admin"
        )
      ) {
        API_URL.post("clear-notifications").then(() => {});
        this.clearNotifications();
      } else {
        API_URL.post(`clear-notifications?user_id=${this.user.id}`).then(
          () => {}
        );
        this.clearNotifications();
      }
    },
    logout() {
      this.$store.dispatch("auth/destroyToken").then(() => {
        window.location.href = "/login";
        this.$router.push("/login");
      });
    },
    showBreadcrumbLink(data) {
      if (data.url) {
        this.breadcrumbUrls.push(data.url);
      } else {
        this.breadcrumbUrls = [];
      }
      this.pageName = data.pageName;
    },
  },
  mounted() {
    this.currUser = this.user;
  },
};
</script>

<style src="./admin.scss" lang="scss" scoped></style>
<style src="./admin-additional.scss" lang="scss" scoped></style>
<style>
.badge {
  height: 20px !important;
  width: 20px !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}
</style>
<style scoped lang="scss">
svg {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  cursor: pointer;
}

.notificationIcon {
  color: white;
}

svg path {
  fill: none;
  stroke: $text_main;
  stroke-width: 4px;
}

svg + svg {
  margin-left: 1.5rem;
}

#top,
#bottom {
  stroke-dasharray: 30, 75.39;
  transition: all 0.6s cubic-bezier(0.6, 0.33, 0.67, 1.29);
}

svg.active #top,
svg.active #bottom {
  stroke-dasharray: 75.39;
  stroke-dashoffset: -60;
}

.wrapper .content-wrapper {
  min-height: calc(100vh - 7rem - 20px) !important;
}

.font {
  font-family: inherit;
}

.notiDropdown {
  max-height: 400px !important;
  overflow-y: scroll;
}

.br-1 {
  padding-left: 1.5rem !important;

  a {
    padding-left: 1rem !important;
    border-left: 1px dashed $sidebar_active_link !important;
  }
}

.menu-is-open .menu3 {
  height: 170.43px !important;
}
</style>
